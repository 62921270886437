export const getConfigs = () => {
  const baseRoute = process.env.REACT_APP_ADMIN_DASHBOARD_API;
  const apiKey = process.env.REACT_APP_ADMIN_DASHBOARD_API_KEY;
  const cognitoHostedUiUrl = process.env.REACT_APP_COGNITO_HOSTED_UI_URL || "";
  const redirectToHostedUi =
    process.env.REACT_APP_REDIRECT_TO_HOSTED_UI === "false" ? false : true;

  const APPLICATION_HOLD_EXTEND_TIME_IN_MINUTES = 1440;
  const MILLISECONDS = 1000;
  const SECONDS = 60;
  const REFETCH_DATA_INTERVAL_TIME = 15 * SECONDS * MILLISECONDS;
  const idleMinutes = 30;
  const defaultIdleTime = idleMinutes * SECONDS * MILLISECONDS;
  const REACT_APP_MAX_IDLE_TIME = process.env.REACT_APP_MAX_IDLE_TIME;
  const maxIdleTime = !REACT_APP_MAX_IDLE_TIME
    ? defaultIdleTime
    : parseInt(REACT_APP_MAX_IDLE_TIME);
  const minTokenTimeLeft = 15 * SECONDS * MILLISECONDS;
  const applicationHoldExtendTimeInMinutes =
    APPLICATION_HOLD_EXTEND_TIME_IN_MINUTES;

  const addendumBaseRoute = process.env.REACT_APP_ADDENDUM_BASE_ROUTE;
  const addendumApiKey = process.env.REACT_APP_ADDENDUM_API_KEY;
  const refetchDataIntervalTime = REFETCH_DATA_INTERVAL_TIME;

  // configurations env variables
  const configurationsBaseRoute = process.env.REACT_APP_CONFIGURATIONS_API;
  const configurationApiKey = process.env.REACT_APP_CONFIGURATIONS_API_KEY;

  return {
    baseRoute,
    apiKey,
    maxIdleTime,
    minTokenTimeLeft,
    applicationHoldExtendTimeInMinutes,
    refetchDataIntervalTime,
    cognitoHostedUiUrl,
    redirectToHostedUi,
    addendumBaseRoute,
    addendumApiKey,
    configurationsBaseRoute,
    configurationApiKey,
  };
};
