import { Tooltip, tooltipClasses } from "@mui/material";
import Chip from "@mui/material/Chip";

const styleMap = {
  success: {
    border: 1,
    borderColor: "#2E7D32",
    bgcolor: "#EAF2EA",
    color: "#2E7D32",
    ml: 2,
  },
  warning: {
    border: 1,
    borderColor: "#ED6C02",
    bgcolor: "#FDF0E6",
    color: "#ED6C02",
    ml: 2,
  },
  error: {
    border: 1,
    borderColor: "#F5222D",
    bgcolor: "#FBEAEA",
    color: "#F5222D",
    ml: 2,
  },
  default: {
    bgColor: "#EDEEEF",
    ml: 2,
  },
};

const componentsProps = {
  popper: {
    sx: {
      [`& .${tooltipClasses.arrow}`]: {
        color: (theme) => theme.palette.common.white,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.common.black,
        boxShadow: 1,
      },
    },
  },
};

const DocumentResultChip = ({ result, issue, id }) => {
  if (!result) return null;
  switch (result.toUpperCase()) {
    case "CLEAN":
      return <Chip sx={styleMap["success"]} label={result} />;
    case "INSUFFICIENT DOCUMENTATION":
      return (
        <Tooltip
          title={issue}
          key={id}
          arrow
          placement="top"
          componentsProps={componentsProps}
        >
          <Chip sx={styleMap["warning"]} label={result} />
        </Tooltip>
      );
    case "EDITED":
      return <Chip sx={styleMap["error"]} label={result} />;
    case "PENDING DOCUMENT VERIFICATION":
      return <Chip sx={styleMap["default"]} label={result} />;
    case "RESUBMITTED - CLEAN":
      return <Chip sx={styleMap["success"]} label={result} />;
    case "RESUBMITTED - INSUFFICIENT":
      return (
        <Tooltip
          title={issue}
          key={id}
          arrow
          placement="top"
          componentsProps={componentsProps}
        >
          <Chip sx={styleMap["warning"]} label={result} />
        </Tooltip>
      );
    case "RESUBMITTED - EDITED":
      return <Chip sx={styleMap["error"]} label={result} />;
    default:
      return <Chip sx={styleMap["default"]} label={result} />;
  }
};

export default DocumentResultChip;
