import { getConfigs } from "../configs/Config";
import {
  setApplicationStatus,
  setApplicationTermsAndConditions,
  setNotification,
  setInitialFilterOptions,
  setApplications,
  setApplication,
  setConfiguration,
} from "src/store/features/applications/applicationsSlice";
import { getHelpRequestsBulk } from "./HelpRequestService";
import { fetchAccessToken, fetchIdToken } from "../utils/Auth";
import { chunk } from "../utils/Utils";
import LocalStorageService from "./LocalStorageService";
import { AddendumsPost, AddendumsPostAddendums } from "src/types/Addendum";
import { mergeAddendumFormsAndResponses } from "src/utils/Addendum";
import {
  LeaseEventId,
  PostLeaseResponse,
  Application,
  GetApplicationsBody,
} from "src/types/Application";
import { GetFilterOptionsBody } from "src/types/FilterOptions";
import { AppThunk } from "src/redux/store";
import { formatArrayToString } from "src/utils/Filter";

export const getApplications = (communityIds: string[] = []): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/applications/search`;

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
      body: JSON.stringify({
        communityIds: communityIds,
      }),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res: GetApplicationsBody) => {
        if (res && res.applications) {
          const applicationIds = res.applications.map(
            (application: Application) => {
              return application.applicationId;
            }
          );

          const applicationIdChunks = chunk(applicationIds, 100);
          applicationIdChunks.map((group) => {
            dispatch(getHelpRequestsBulk(group));
          });
        }

        dispatch(setApplications(res));
      })
      .catch((error) => {
        console.log("error on getting applications ", error);
        dispatch(setApplications({ applications: [], count: 0 }));
      });
  };
};

export const getApplicationById = (applicationId): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/applications/${applicationId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    headers.append("x-access-token", fetchAccessToken() || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        const { application: applicationFromLocal } =
          LocalStorageService.getApplication(applicationId);

        const application = {
          ...applicationFromLocal,
          ...res,
        };

        dispatch(setApplication(application));
        LocalStorageService.setApplicationInLocal(
          { ...application, lastFetchedDate: new Date().toJSON() },
          null
        );
      })
      .catch((error) => {
        console.log(
          `error on getting application by id - ${applicationId}`,
          error
        );
      });
  };
};

export const getFilterOptions = (): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/filter-options`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");

    const options = {
      method,
      headers,
    };

    fetch(route, options)
      .then((data) => data.json())
      .then((res: GetFilterOptionsBody) => {
        dispatch(setInitialFilterOptions(res));
      })
      .catch((error) => {
        console.log("error on getting initial filter options ", error);
      });
  };
};

export const updateUserFilterSelection = (selectedOptions): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/filter-options`;

  return () => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      regions: formatArrayToString(selectedOptions.regions),
      markets: formatArrayToString(selectedOptions.markets),
      communities: formatArrayToString(selectedOptions.communities),
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then(() => {
        /**
         * No need to do anything here. Reaching this point
         * simply means that the new filter selections were saved.
         */
      })
      .catch((error) => {
        console.log("Error on getting initial filter options ", error);
      });
  };
};

export const getApplicationStatus = (applicationId, applicantId): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/payments/${applicationId}/applicant/${applicantId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(setApplicationStatus(res));
      })
      .catch((error) => {
        console.log("Error on getting application status ", error);
      });
  };
};

export const getApplicationTermsAndConditions = (applicantId): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/terms-and-conditions/${applicantId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(setApplicationTermsAndConditions(res));
      })
      .catch((error) => {
        console.log("Error on getting application documents ", error);
      });
  };
};

export const cancelApplication = (applicationId, unitId): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/cancel-application`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(Error);
        }
        dispatch(
          setNotification({
            type: "INFO",
            message: `Application for Unit ${unitId} has been successfully cancelled`,
            origin: "cancelApplication",
          })
        );
      })
      .catch((error) => {
        console.log("Error cancelling ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to cancel Application",
            message:
              "There was an error cancelling the application. Please try again.",
            origin: "cancelApplication",
          })
        );
      });
  };
};

export const saveAddendumAnswers = (
  applicationId: number,
  addendumAnswers: AddendumsPostAddendums[]
): AppThunk => {
  const { baseRoute, apiKey } = getConfigs();
  const route = `${baseRoute}/applications/${applicationId}/addendums`;
  const accessToken = sessionStorage.getItem("access_token") || "";

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("x-access-token", accessToken);
    headers.append("x-api-key", apiKey ?? "");
    const data: AddendumsPost = {
      id: applicationId,
      forms: addendumAnswers,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(data),
    };

    fetch(route, options)
      .then((response) => response.json())
      .then(() => {
        const { application: applicationFromLocal } =
          LocalStorageService.getApplication(applicationId);

        dispatch(
          setNotification({
            type: "INFO",
            message: "Success! Lease addendum answers submitted",
            origin: "saveAddendumAnswers",
          })
        );

        const newAddendums = mergeAddendumFormsAndResponses(
          applicationFromLocal.addendums,
          addendumAnswers
        );
        const newApplicationData = {
          ...(applicationFromLocal ?? {}),
          addendums: newAddendums,
        };

        dispatch(setApplication(newApplicationData));
        LocalStorageService.setApplicationInLocal(newApplicationData, null);
        dispatch(getApplicationById(applicationId));
      })
      .catch((error) => {
        console.log("Error saving addendum answers ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to save addendum answers",
            message: "Error trying to save Lease addendum answers",
          })
        );
      });
  };
};

export const completeAndRemoveApplication = (
  applicationId,
  unitId
): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/complete-and-remove`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };

    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Application for Unit ${unitId} has been successfully finished`,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to complete Application",
              message:
                "There was an error completing the application.\nPlease try again.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error on completing application ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to complete Application",
            message:
              "There was an error completing the application.\nPlease try again.",
          })
        );
      });
  };
};

export const submitCreditScreening = (
  applicationId,
  applicantId,
  applicantName
): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/run-fadv-screening`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
      applicantId: applicantId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    const onErrorInstructions =
      "Please click view/edit application, check each applicant's entered address and make any necessary edits, then click submit again.";
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.errors && !res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Credit Screening for ${applicantName} is in progress`,
            })
          );
        } else {
          const errorDescription =
            res.errors["Bad Request"] && res.errors["Bad Request"][0]
              ? JSON.parse(res.errors["Bad Request"][0]).ErrorDescription
              : undefined;
          const message = `${onErrorInstructions}${
            errorDescription
              ? `\n\nDetails from screening service: ${errorDescription}`
              : ""
          }`;
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to run credit screening",
              message: message,
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error credit screening ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to run credit screening",
            message: onErrorInstructions,
          })
        );
      });
  };
};

export const confirmDeposit = (applicationId, applicantName): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/application/${applicationId}/confirm-deposit-paid`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.errors && !res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Condition Met, increased security deposit met for ${
                applicantName || "applicant"
              }, Application ${applicationId}`,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to confirm increased deposit paid",
              message:
                "There was an error confirming increased deposit paid.\nPlease try again.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error confirming ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to confirm increased deposit paid",
            message:
              "There was an error confirming increased deposit paid.\nPlease try again.",
          })
        );
      });
  };
};

export const additionalDeposit = (applicationId, applicantName): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/applications/${applicationId}/additional-deposit`;
  const accessToken = sessionStorage.getItem("access_token") || "";
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("x-access-token", accessToken);
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.errors && !res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Condition Met, increased security deposit met for ${
                applicantName || "applicant"
              }, Application ${applicationId}`,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to confirm increased deposit paid",
              message:
                "There was an error confirming increased deposit paid.\nPlease try again.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error confirming ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to confirm increased deposit paid",
            message:
              "There was an error confirming increased deposit paid.\nPlease try again.",
          })
        );
      });
  };
};

export const updateApplicantById = (applicantId, applicantData): AppThunk => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/applicants/${applicantId}`;

  return (dispatch) => {
    const method = "PUT";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      firstName: applicantData.firstName,
      middleName: applicantData.middleName,
      lastName: applicantData.lastName,
      dob: applicantData.dob,
      address1: applicantData.address1,
      address2: applicantData.address2,
      city: applicantData.city,
      state: applicantData.state,
      zip: applicantData.zip,
      emailAddress: applicantData.emailAddress,
      nonUSPhone: applicantData.nonUSPhone,
      isSSN: applicantData.isSSN,
      sMSPreference: applicantData.sMSPreference,
      emailPreference: applicantData.emailPreference,
      isMiddleName: applicantData.isMiddleName,
      phoneNumberType: applicantData.phoneNumberType,
      phoneNumber: applicantData.phoneNumber,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (res.status === 400) {
          dispatch(
            setNotification({
              type: "VALIDATION_ERROR",
              title: "Update Applicant Info",
              message: JSON.stringify(res.errors),
            })
          );
          return;
        }

        dispatch(
          setNotification({
            type: "INFO",
            title: "Update Applicant Info",
            message: `Applicant Info for applicant ${applicantId} has been successfully updated`,
          })
        );
        return res;
      })
      .catch((error) => {
        console.log(
          `Error on updating applicant info for applicant - ${applicantId}`,
          error
        );
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Update Applicant Info",
            message: "Unable to update applicant information",
          })
        );
      });
  };
};

export const postApplicationHoldDateFromNow = (
  applicationId,
  minutes
): AppThunk => {
  const { baseRoute, apiKey } = getConfigs();

  const route = `${baseRoute}/applications/${applicationId}/holdfromnow?minutes=${minutes}`;

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      "x-id-token": fetchIdToken()?.replace("Bearer ", "") || "",
      "x-api-key": apiKey || "",
    });
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then(() => {
        dispatch(
          setNotification({
            type: "INFO",
            message: `Hold Clock for Application ${applicationId} has been successfully reset`,
          })
        );
      })
      .catch((error) => {
        console.log("error on postApplicationHoldDateFromNow ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: `Unable to reset Hold Clock`,
            message: `There was an error reseting Hold Clock for Application ${applicationId}.\nPlease try again.`,
          })
        );
      });
  };
};

export const postLease = (applicationId, leaseEventId): AppThunk => {
  const { baseRoute, apiKey } = getConfigs();
  const route = `${baseRoute}/applications/${applicationId}/lease`;

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      "x-id-token": fetchIdToken()?.replace("Bearer ", "") || "",
      "x-api-key": apiKey || "",
    });

    const body = JSON.stringify({
      applicationId,
      leaseEventId,
    });

    const options = {
      method,
      headers,
      body,
    };

    return fetch(route, options)
      .then((data) => data.json())
      .then((data: PostLeaseResponse) => {
        if (leaseEventId === LeaseEventId.LeaseGenerated) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Lease has been generated and successfully sent out`,
              origin: "postLease",
            })
          );
        } else if (leaseEventId === LeaseEventId.LeaseResent) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Lease has been successfully sent out`,
              origin: "postLease",
            })
          );
        }

        const { application: applicationFromLocal } =
          LocalStorageService.getApplication(applicationId);

        const newApplicationData = {
          ...(applicationFromLocal ?? {}),
          lease: {
            ...(applicationFromLocal?.lease || {}),
            leaseEvents: [
              data,
              ...(applicationFromLocal?.lease?.leaseEvents || []),
            ],
          },
        };

        dispatch(setApplication(newApplicationData));
        LocalStorageService.setApplicationInLocal(newApplicationData, null);
        dispatch(getApplicationById(applicationId));
      })
      .catch((error) => {
        if (leaseEventId === LeaseEventId.LeaseGenerated) {
          dispatch(
            setNotification({
              type: "ERROR",
              message: `Error generating lease, please try again`,
              origin: "postLease",
            })
          );
        } else if (leaseEventId === LeaseEventId.LeaseResent) {
          dispatch(
            setNotification({
              type: "ERROR",
              message: `Error sending out lease, please try again`,
              origin: "postLease",
            })
          );
        }
        console.log("error on postLease ", error);
      });
  };
};

export const getConfigurations = (): AppThunk => {
  const { configurationsBaseRoute, configurationApiKey } = getConfigs();

  return (dispatch) => {
    const route = `${configurationsBaseRoute}`;
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("x-api-key", configurationApiKey || "");
    headers.append("x-access-token", fetchAccessToken() || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(setConfiguration(res));
      })
      .catch((error) => {
        console.log("Error retreiving configuration", error);
      });
  };
};
