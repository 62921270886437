import { Tooltip, tooltipClasses } from "@mui/material";
import { Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import DocumentResultChip from "src/components/Shared/DocumentResultChip";
import { convertToLocalAndFormatDate, formatTime } from "src/utils/Utils";
import { ReduxApplication } from "src/types/Application";
import { getDocumentScreeningData } from "src/utils/Screening";

interface DocumentScreeningProps {
  application: ReduxApplication;
}

const getPanelTitle = (result: string) => {
  return (
    <>
      SNAPPT Document Validation
      {result && <DocumentResultChip result={result} issue="" id="" />}
    </>
  );
};
const componentsProps = {
  popper: {
    sx: {
      [`& .${tooltipClasses.arrow}`]: {
        color: (theme) => theme.palette.common.white,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.common.black,
        boxShadow: 1,
      },
    },
  },
};

const DocumentScreening = ({ application }: DocumentScreeningProps) => {
  const { Panel } = Collapse;
  const [documentationData, setDocumentationData] = useState([]);

  useEffect(() => {
    const formattedApplicantData = application?.applicants.map((applicant) => {
      return getDocumentScreeningData(applicant, applicant.applicantScreening);
    });
    prepareApplicantsData(formattedApplicantData);
  }, [application]);

  const prepareApplicantsData = (applicants: any[]) => {
    const applicantsData: any = [];
    applicants.forEach((applicant) => {
      const formattedDate = applicant?.lastUpdated
        ? `${formatTime(applicant?.lastUpdated)}, ${convertToLocalAndFormatDate(
            applicant?.lastUpdated
          )}`
        : "-";

      const data = {
        fullName: (
          <Tooltip
            title={applicant?.emailAddress}
            key={applicant?.id}
            arrow
            placement="top"
            componentsProps={componentsProps}
          >
            <span>
              {`${applicant?.firstName || ""} ${applicant?.lastName || ""}`}
            </span>
          </Tooltip>
        ),
        reportStatus: applicant?.snapptStatus,
        result: applicant?.result ? (
          <Tooltip
            title={applicant?.emailAddress}
            key={applicant?.id}
            arrow
            placement="top"
            componentsProps={componentsProps}
          >
            <DocumentResultChip
              result={applicant?.result}
              issue={applicant?.issue}
              id={applicant?.id}
            />
          </Tooltip>
        ) : (
          "-"
        ),
        documents: applicant?.documents ?? "-",
        receivedDate: formattedDate,
      };
      applicantsData.push(data);
    });
    setDocumentationData(applicantsData);
  };

  return (
    <Collapse defaultActiveKey={"3"} bordered={false}>
      <Panel header={getPanelTitle("Pending Document Verification")} key="3">
        <div className=" col-sm-12 col-md-12">
          <Table
            dataSource={documentationData}
            columns={[
              {
                title: "Applicants",
                dataIndex: "fullName",
                key: "fullName",
              },
              {
                title: "Report Status",
                dataIndex: "reportStatus",
                key: "reportStatus",
              },
              {
                title: "Result",
                dataIndex: "result",
                key: "result",
              },
              {
                title: "Documents",
                dataIndex: "documents",
                key: "documents",
              },
              {
                title: "Received",
                dataIndex: "receivedDate",
                key: "receivedDate",
              },
            ]}
            pagination={false}
          />
        </div>
      </Panel>
    </Collapse>
  );
};

export default DocumentScreening;
