export enum ScreeningStatus {
  Approved = 1,
  ApprovedWithConditions,
  Declined,
  FurtherReview,
  Pending,
  GuarantorRequired,
  DocumentSubmissionCompleted,
  Retry,
  ProblemResolution,
  ApprovedWithConditionsMet,
}

export interface Issue {
  id: number;
  applicantScreeningId: number;
  type: string;
  description: string;
}

export const ScreeningTypes = {
  ssn: "SSN",
  address: "Address",
};

export const ScreeningDocumentResult = {
  clean: "Clean",
  insufficientDocumentation: "insufficient documentation",
  edited: "Edited",
  resubmittedClean: "Resubmitted - Clean",
  resubmittedInsufficient: "Resubmitted - Insufficient",
  resubmittedEdited: "Resubmitted - Edited",
  pendingDocumentationVerification: "Pending Document Verification",
};
