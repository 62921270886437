import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  additionalDeposit,
  confirmDeposit,
  getConfigurations,
} from "../../services/AdminDashService";

const ConfirmIncreasedDepositModal = (props) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );

  const configuration = useAppSelector(
    (state) => state.applications.configuration
  );

  const { showModal, setShowModal, application } = props;
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [screeningEnabled, setScreeningEnabled] = useState(false);

  useEffect(() => {
    if (
      notification &&
      notification.type === "INFO" &&
      notification.message?.startsWith("Condition Met")
    ) {
      window.localStorage.setItem("REFRESH_APPS", Math.random().toString());
      resetModal();
      window.scrollTo(0, 0);
    } else if (
      notification &&
      notification.type === "ERROR" &&
      notification.message?.startsWith(
        "There was an error confirming increased deposit paid"
      )
    ) {
      resetModal();
    }
  }, [notification]);

  useEffect(() => {
    if (configuration && application) {
      const screeningFeature = configuration.features?.screening as {
        [key: string]: string[] | null;
      };

      const communitiesScreeningFeature = screeningFeature?.communities || null;
      const hasCommunity = Boolean(
        (communitiesScreeningFeature &&
          communitiesScreeningFeature.length === 0) ||
          communitiesScreeningFeature?.find(
            (communityScreening: string) =>
              communityScreening === application?.communityId
          )
      );
      setScreeningEnabled(hasCommunity);
    }
  }, [configuration, application]);

  useEffect(() => {
    dispatch(getConfigurations());
  }, []);

  const fullName = `${application.primaryApplicantFirstName} ${application.primaryApplicantLastName}`;

  const confirmIncrease = () => {
    setDisableSubmit(true);
    setShowSpinner(true);

    if (screeningEnabled) {
      dispatch(additionalDeposit(application.applicationId, fullName));
    } else {
      dispatch(confirmDeposit(application.applicationId, fullName));
    }
  };

  const resetModal = () => {
    setShowModal(false);
    setShowSpinner(false);
    setDisableSubmit(false);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      className="confirm-increase-modal"
    >
      <Modal.Header className="border-bottom-0">
        <Modal.Title>
          <span className="cancel-icon">!</span>Confirm Increased Deposit Paid
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="details block-labels mt-0">
          <p className="mb-1">
            <span className="fw-bold">Application: </span>
            {application &&
              `${application.communityName} ${application.unitId}`}
          </p>
          <p className="mb-4">
            <span className="fw-bold">Primary Applicant: </span>
            {application && fullName}
          </p>
          <p>
            This application is approved with conditions. Please click
            &quot;confirm&quot; button to verify that the applicant has paid the
            increased security deposit to meet conditions for approval.
            Otherwise, click the &quot;cancel&quot; button to close this modal.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button
          className="cancel-btn"
          variant="secondary"
          onClick={() => setShowModal(false)}
          disabled={disableSubmit}
        >
          Cancel
        </Button>
        <Button
          className="btn-blue-grey primary"
          variant="primary"
          onClick={() => confirmIncrease()}
          disabled={disableSubmit}
        >
          {showSpinner && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
          )}

          {showSpinner ? "Loading..." : "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmIncreasedDepositModal;
